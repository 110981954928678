import React, { useEffect, useMemo } from 'react';
import { roomsQuery, roomsService, roomTypeQuery, roomTypeService } from '../../state';
import { useObservable } from '../utils';
import { AppColors } from '../theme';

interface Props {
  propertyId: string;
  roomTypeId: string;
  roomId?: string;
  hideRoomNumber?: boolean;
}

export const RoomDisplay: React.FC<Props> = props => {
  const { roomId, hideRoomNumber } = props;
  if (roomId) {
    return (
      <RoomNumberDisplay {...props} roomId={roomId} hideRoomNumber={hideRoomNumber ?? false} />
    );
  } else {
    return <RoomTypeOnlyDisplay {...props} />;
  }
};

const RoomTypeOnlyDisplay: React.FC<Omit<Props, 'roomId'>> = ({ roomTypeId }) => {
  useEffect(() => {
    roomTypeService.getRoomType(roomTypeId);
  }, [roomTypeId]);

  const query = useMemo(() => roomTypeQuery.selectEntity(roomTypeId), [roomTypeId]);
  const roomType = useObservable(query);

  if (!roomType) return null;

  return <>{roomType?.name}</>;
};

const RoomNumberDisplay: React.FC<Required<Props>> = ({
  propertyId,
  roomId,
  hideRoomNumber,
  roomTypeId,
}) => {
  useEffect(() => {
    roomsService.getRoomNumber(propertyId, roomId);
  }, [propertyId, roomId]);

  const query = useMemo(() => roomsQuery.selectEntity(roomId), [roomId]);
  const room = useObservable(query);

  if (!room) return null;

  const roomTypeStyle =
    hideRoomNumber && roomTypeId !== room.roomTypeId ? { color: AppColors.Green } : {};

  return (
    <>
      <span style={roomTypeStyle}>{room.roomType.name}</span>
      <>
        {!hideRoomNumber && (
          <>
            <span> / {room.roomNumber}</span>
          </>
        )}
      </>
    </>
  );
};
