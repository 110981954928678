import React from 'react';
import { Box, Divider } from '@material-ui/core';

import { CheckInRequirement, Reservation } from '@lib/state';
import { PaymentRequiredForm, EnumValueDisplay, LineItemDisplay } from '../components';
import { PropsOf, useGuestPaymentMethod } from '../utils';

interface Props
  extends Omit<
    PropsOf<typeof PaymentRequiredForm>,
    'submitLabel' | 'propertyId' | 'paymentMethods'
  > {
  reservation: Reservation;
  requirements: Array<CheckInRequirement>;
}

export const CheckInRequirements: React.FC<Props> = ({
  requirements,
  reservation,
  guest,
  ...props
}) => {
  const paymentMethods = useGuestPaymentMethod(
    reservation.userId ?? undefined,
    reservation.propertyId,
    reservation.affiliation?.corporateAccountId ?? undefined
  );

  return (
    <PaymentRequiredForm
      guest={guest}
      submitLabel="Check In"
      paymentMethods={{ creditCards: paymentMethods.creditCards, invoiceAccounts: [] }} // guest cannot use invoice accounts to pay for any check-in requirements
      {...props}
    >
      {requirements.map(x => (
        <LineItemDisplay
          key={x.id}
          label={
            !!x.description ? x.description : <EnumValueDisplay value={x.type} variant="body2" />
          }
          amount={x.amount}
          variant="body2"
        />
      ))}
      <LineItemDisplay
        label="Tax"
        amount={requirements.reduce((sum, x) => sum + x.tax.total, 0)}
        variant="body2"
      />
      <Box mt={1} mb={0.5}>
        <Divider />
      </Box>
      <LineItemDisplay
        label="Total"
        amount={requirements.reduce((sum, x) => sum + x.total, 0)}
        variant="subtitle1"
      />
    </PaymentRequiredForm>
  );
};
