import { FormStatus, RoomType } from '@lib/state';
import { DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import { StyledDialog } from '../../components';
import React from 'react';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  roomType?: RoomType;
  status: FormStatus;
}

export function RoomTypeConfirmation({ open, setOpen, roomType, status }: Props) {
  return (
    <StyledDialog open={open} title="Confirm room type" onClose={() => {}} disablePortal>
      <DialogContent data-testid="confirmRoomTypeDisplay">
        <Typography>Are you sure you want to book a reservation for {roomType?.name}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="default"
          disabled={!!status && status === FormStatus.Pending}
          onClick={() => setOpen(false)}
          data-testid="roomTypeCancelButton"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!!status && status === FormStatus.Pending}
          onClick={() => setOpen(false)}
          data-testid="roomTypeConfirmButton"
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
