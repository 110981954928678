import { parseISO } from 'date-fns';

import { Properties, Reservations } from '@lib/state';

export interface RoomCount {
  id: string;
  propertyId: string;

  date: Date;

  /**
   * Adjusted number of rooms that are neither occupied nor reserved
   */
  availableRooms: number;

  /**
   * Rooms occupied by a guest (count of guest stays)
   */
  occupiedRooms: number;

  /**
   * Rooms reserved but not yet occupied (count of open reservations)
   */
  reservedRooms: number;

  /**
   * Rooms reserved expected to check-in (count of open reservations with matching check-in date)
   */
  checkInCount: number;

  /**
   * Rooms reserved expected to check-out (count of checked-in reservations with matching check-out date)
   */
  checkOutCount: number;

  /**
   * Rooms in service
   */
  totalRooms: number;

  /**
   * Clean rooms
   */
  readyRooms: number;

  /**
   * Inventory adjustments
   */
  adjustments: number;

  /**
   * Room counts for today, which adjust for rooms expected to check-in (including check-in today and no-shows still eligible to check-in).
   */
  today: {
    occupiedRooms: number;
    checkInCount: number;
  };
}

export function mapRoomCount(
  propertyId: string,
  property: Properties.RoomCountModel,
  reservation: Reservations.RoomCountModel
): RoomCount {
  const checkInCount = property.checkInCount + reservation.checkInCount;
  const checkOutCount = property.checkOutCount + reservation.checkOutCount;

  const committedRooms = property.occupiedRooms + reservation.reservedRooms;

  // we consider:
  //  - checkOut rooms as occupied until they checkOut, and
  //  - checkIn rooms as unoccupied until they checkIn
  const occupiedRooms = Math.ceil(committedRooms + checkOutCount - checkInCount);

  const availableRooms =
    reservation.totalRooms - Math.ceil(committedRooms) + reservation.inventoryAdjustments;

  const checkInCountRounded = Math.ceil(checkInCount);
  const checkOutCountRounded = Math.ceil(checkOutCount);

  return {
    id: property.date,
    propertyId,
    date: parseISO(property.date),
    availableRooms,
    occupiedRooms,
    checkInCount: checkInCountRounded,
    checkOutCount: checkOutCountRounded,
    reservedRooms: reservation.reservedRooms,
    totalRooms: reservation.totalRooms,
    readyRooms: reservation.readyRooms,
    adjustments: reservation.inventoryAdjustments,

    today: {
      occupiedRooms: property.occupiedRooms + checkOutCountRounded,
      checkInCount: checkInCountRounded, // includes all open reservations eligible to check-in today
    },
  };
}
