import { Properties } from '../api';
import { HousekeepingStatusEnum, ShowHousekeepingStatus } from '../rooms';

export type HousekeepingAssignment = Properties.HousekeepingRoomAssignmentModel & {
  serviceDisplay: string;
};

export type StartHousekeepingAssignmentRequest = Properties.StartHousekeepingRoomAssignmentRequest;

export enum RoomAssignmentTypeEnum {
  Assigned = 'Assigned',
  Completed = 'Completed',
  UnAssigned = 'UnAssigned',
  NotCompleted = 'Not Completed',
}

function assigned(room: HousekeepingAssignment) {
  return !!room.assignedTime;
}

function completed(room: HousekeepingAssignment) {
  return !!room.endTime;
}

export const filterRoomAssignmentByType = (
  rooms: HousekeepingAssignment[],
  status?: RoomAssignmentTypeEnum
) => {
  switch (status) {
    case RoomAssignmentTypeEnum.Assigned:
      return rooms.filter(room => assigned(room));
    case RoomAssignmentTypeEnum.Completed:
      return rooms.filter(room => completed(room));
    case RoomAssignmentTypeEnum.UnAssigned:
      return rooms.filter(room => !assigned(room));
    case RoomAssignmentTypeEnum.NotCompleted:
      return rooms.filter(room => assigned(room) && !completed(room));
    default:
      return rooms;
  }
};

export const filterRoomAssignmentByStatus = (rooms: HousekeepingAssignment[], status?: string) => {
  if (!status) return rooms;

  if (status === 'UpcomingCheckout') return rooms.filter(room => room.isUpcomingCheckOut);

  return rooms.filter(room => room.serviceDisplay === status || room.serviceRequested === status);
};

/**
Returns total number of assignments that have a housekeeper assigned and by the service requested.;
*/
export const totalAssignedHousekeepingByRequested = (
  rooms: HousekeepingAssignment[],
  serviceRequested: HousekeepingStatusEnum
) => {
  if (!rooms) return 0;

  switch (serviceRequested) {
    case HousekeepingStatusEnum.Checkout:
      return rooms.filter(
        room =>
          (room.serviceRequested === serviceRequested ||
            (room.serviceRequested === HousekeepingStatusEnum.NoCleaningRequired &&
              room.isUpcomingCheckOut)) &&
          !!room.housekeeperUserId
      ).length;
    default:
      return rooms.filter(
        room =>
          room.serviceRequested === serviceRequested &&
          !room.isUpcomingCheckOut &&
          !!room.housekeeperUserId
      ).length;
  }
};

/**
Returns total number of assignments that have a housekeeper assigned and by the service performed.;
*/
export const totalHousekeepingByPerformed = (
  rooms: HousekeepingAssignment[],
  servicePerformed: string
) => {
  if (!rooms) return 0;

  return rooms.filter(
    room => room.servicePerformed === servicePerformed && !!room.housekeeperUserId
  ).length;
};

/**
Returns total number of assignments by the service requested.;
*/
export const totalDueHousekeepingByRequested = (
  rooms: HousekeepingAssignment[],
  serviceRequested: HousekeepingStatusEnum
) => {
  if (!rooms) return 0;

  switch (serviceRequested) {
    case HousekeepingStatusEnum.Checkout:
      return rooms.filter(
        room => room.serviceRequested === serviceRequested || room.isUpcomingCheckOut
      ).length;
    default:
      return rooms.filter(
        room => room.serviceRequested === serviceRequested && !room.isUpcomingCheckOut
      ).length;
  }
};

/**
Returns total number of assignments were refused (come back tomorrow) by service requested;
*/
export const totalHousekeepingRefusedByRequested = (
  rooms: HousekeepingAssignment[],
  serviceRequested: string
) => {
  if (!rooms) return 0;

  return rooms.filter(
    room =>
      room.serviceRequested === serviceRequested &&
      room.servicePerformed === HousekeepingStatusEnum.NoCleaningRequired
  ).length;
};

export const mapToHousekeepingAssignmentModelList = (
  list: Properties.HousekeepingRoomAssignmentModel[]
): HousekeepingAssignment[] =>
  list.map((item, idx) => ({
    ...item,
    serviceDisplay: ShowHousekeepingStatus(item.serviceRequested, item.isUpcomingCheckOut),
    id: item.id ?? item.roomId,
  }));
