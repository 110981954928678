import React, { useMemo } from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import { Reservation, ReservationStatus } from '@lib/state';
import { useCommonStyle, DateDisplay, EnumValueDisplay, RoomDisplay } from '@lib/common';
import { parseISO } from 'date-fns';
import { GuestStay } from 'app/state';

interface Props {
  reservation: Reservation;
  guestStays: GuestStay[];
}

export const ReservationInfoMetaData: React.FC<Props> = ({ reservation, guestStays }) => {
  const styles = useCommonStyle();

  const room = useMemo(() => {
    return reservation.rooms[0];
  }, [reservation]);

  return (
    <Box height="100%" clone>
      <Paper className={styles.paper} elevation={3} square>
        <Typography variant="h5" paragraph>
          Reservation Info
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography>
              <strong>Reservation Number</strong>
            </Typography>
            <Typography>{reservation.recordNumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Status</strong>
            </Typography>
            <EnumValueDisplay value={reservation.status} />
            {reservation.status === ReservationStatus.CheckedIn && (
              <>{guestStays.map(m => m.roomNumber).join(',')}</>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Reserved Room Type</strong>
            </Typography>
            <Typography>
              <RoomDisplay propertyId={reservation.propertyId} roomTypeId={room.roomTypeId} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Current Room Type</strong>
            </Typography>
            <Typography>
              <RoomDisplay
                propertyId={reservation.propertyId}
                roomTypeId={room.roomTypeId}
                roomId={room.roomId ?? undefined}
                hideRoomNumber={true}
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Scheduled Check In</strong>
            </Typography>
            <DateDisplay
              date={parseISO(reservation.checkInDate)}
              dateFormat="M/d/yyyy"
              testId="reservationInfoCheckInDateDisplay"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Scheduled Check Out</strong>
            </Typography>
            <DateDisplay
              date={parseISO(reservation.checkOutDate)}
              dateFormat="M/d/yyyy"
              testId="reservationInfoCheckOutDateDisplay"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Actual Check In</strong>
            </Typography>
            <DateDisplay
              date={!!reservation.actualCheckIn ? parseISO(reservation.actualCheckIn) : undefined}
              dateFormat="M/d/yyyy h:mm a"
            >
              {reservation.status === ReservationStatus.NoShow ? 'No-Show' : null}
            </DateDisplay>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Actual Check Out</strong>
              <DateDisplay
                date={
                  !!reservation.actualCheckOut ? parseISO(reservation.actualCheckOut) : undefined
                }
                dateFormat="M/d/yyyy h:mm a"
              />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
